import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import Container from "@components/v2/Container";

const Wrapper = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e19mpuyz0"
} : {
  target: "e19mpuyz0",
  label: "Wrapper"
})(process.env.NODE_ENV === "production" ? {
  name: "sl5phz",
  styles: "display:flex;height:38px;align-items:center;@media (min-width: 1024px){height:6rem;}"
} : {
  name: "sl5phz",
  styles: "display:flex;height:38px;align-items:center;@media (min-width: 1024px){height:6rem;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2xheW91dHMvdjIvQmxvZy9jb21wb25lbnRzL0Jhci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSWdCIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL2xheW91dHMvdjIvQmxvZy9jb21wb25lbnRzL0Jhci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgdHcgZnJvbSBcInR3aW4ubWFjcm9cIlxuXG5pbXBvcnQgQ29udGFpbmVyIGZyb20gXCJAY29tcG9uZW50cy92Mi9Db250YWluZXJcIlxuXG5jb25zdCBXcmFwcGVyID0gdHcuZGl2YFxuICAgIGgtWzM4cHhdIGxnOmgtMjRcbiAgICBmbGV4IGl0ZW1zLWNlbnRlclxuYFxuXG5pbnRlcmZhY2UgQmFyUHJvcHMge1xuICAgIGNsYXNzTmFtZT86IHN0cmluZ1xufVxuXG5jb25zdCBCYXI6IFJlYWN0LkZDPEJhclByb3BzPiA9ICh7IGNoaWxkcmVuLCBjbGFzc05hbWUgfSkgPT4gKFxuICAgIDxXcmFwcGVyIGNsYXNzTmFtZT17Y2xhc3NOYW1lfT5cbiAgICAgICAgPENvbnRhaW5lcj57Y2hpbGRyZW59PC9Db250YWluZXI+XG4gICAgPC9XcmFwcGVyPlxuKVxuXG5leHBvcnQgZGVmYXVsdCBCYXJcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

interface BarProps {
  className?: string;
}

const Bar: React.FC<BarProps> = ({
  children,
  className
}) => <Wrapper className={className}>
        <Container>{children}</Container>
    </Wrapper>;

export default Bar;