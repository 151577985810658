import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import Input from "@components/v2/Input";
import { Button } from "@components/v3/Button";
import Icons from "@components/v3/Icons";
interface SearchDesktopProps {
  onSearch: (value: string) => void;
}

const SearchDesktop: React.FC<SearchDesktopProps> = ({
  onSearch
}) => {
  const router = useRouter();
  const {
    q: searchQuery
  } = router.query;
  const {
    t
  } = useTranslation("blog");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (searchQuery) setSearch(String(searchQuery));
  }, [searchQuery]);

  const handleSearch = () => onSearch(search);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearch();
  };

  return <form onSubmit={handleSubmit}>
            <Input name='search' placeholder={t("search")} value={search} onChange={setSearch} groupClassName='!m-0 w-[328px]' inputClassName='!text-sm' suffix={<Button type='submit' variant='secondary' className='!w-[72px]'>
                        <Icons icon='Search' width={24} height={24} />
                    </Button>} suffixClassName='!m-0' />
        </form>;
};

export default SearchDesktop;